exports.components = {
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-dyncomparator-js": () => import("./../../../src/pages/dyncomparator.js" /* webpackChunkName: "component---src-pages-dyncomparator-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-roboassist-js": () => import("./../../../src/pages/roboassist.js" /* webpackChunkName: "component---src-pages-roboassist-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-1-js": () => import("./../../../src/pages/test1.js" /* webpackChunkName: "component---src-pages-test-1-js" */),
  "component---src-pages-test-2-js": () => import("./../../../src/pages/test2.js" /* webpackChunkName: "component---src-pages-test-2-js" */),
  "component---src-pages-test-3-js": () => import("./../../../src/pages/test3.js" /* webpackChunkName: "component---src-pages-test-3-js" */),
  "component---src-pages-test-4-js": () => import("./../../../src/pages/test4.js" /* webpackChunkName: "component---src-pages-test-4-js" */),
  "component---src-pages-test-5-js": () => import("./../../../src/pages/test5.js" /* webpackChunkName: "component---src-pages-test-5-js" */),
  "component---src-pages-test-6-js": () => import("./../../../src/pages/test6.js" /* webpackChunkName: "component---src-pages-test-6-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-comparator-js": () => import("./../../../src/templates/comparator.js" /* webpackChunkName: "component---src-templates-comparator-js" */)
}

